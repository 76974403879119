<template>
    <div class="contentThree">
        <div class="cthBox">
            <!-- 左边图片 -->
            <div class="cthLeftImg">
                <img
                    class="cthImg wow animate__animated animate__slideInLeft"
                    src="https://iv.okvu.cn/vugw/img/img3.png"
                    alt=""
                />
            </div>
            <!-- 右边文字 -->
            <div class="cthRightText wow animate__animated animate__slideInRight">
                <!-- 顶部图片 -->
                <div class="cthTopImg">
                    <img class="cttImg" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="cthBig">独到的威有网站改版方法论</div>
                <div class="cthSmall">
                    <ul>
                        <li>
                            <p class="cth1">
                                <img src="http://vu818.com/cn/images/edit/5-box-1-dot.png" alt="" class="chImg" />
                                <span> 尽量不改习惯：</span
                                >分类顺序、关键栏目层级、功能操作“尽量不改”，以免老用户不适应，重要信息传递不全。
                            </p>
                        </li>
                        <li>
                            <p class="cth1">
                                <img src="http://vu818.com/cn/images/edit/5-box-1-dot.png" alt="" class="chImg" />
                                <span> 整体诊断服务：</span
                                >改版不同于新建，我们根据这些历史数据，整体诊断、分析并总结存在问题，制定诊断分析报告。
                            </p>
                        </li>
                        <li>
                            <p class="cth1">
                                <img src="http://vu818.com/cn/images/edit/5-box-1-dot.png" alt="" class="chImg" />
                                <span> 有根据的改版：</span>有凭有据地探讨问题所在，制定网站改版方案。
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentThree",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.contentThree {
    width: 100%;
    height: 600px;
    background-color: #f7f7f7;
    .cthBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        // 左边图片
        .cthLeftImg {
            width: 50%;
            height: 80%;
            float: left;
            margin-top: 5%;
            .cthImg {
                margin: 0 auto;
                display: block;
                margin-top: 50px;
            }
        }
        // 右边文字
        .cthRightText {
            width: 40%;
            height: 60%;
            position: absolute;
            right: 5%;
            bottom: 20%;
            // 顶部图片
            .cthTopImg {
                width: 75px;
                height: 70px;
                position: relative;
                overflow: hidden;
                .cttImg {
                    position: absolute;
                    top: -145px;
                    left: -389px;
                }
            }
            .cthBig {
                width: 100%;
                height: 70px;
                line-height: 70px;
                font-size: 30px;
                font-weight: 900;
            }
            .cthSmall {
                width: 100%;
                height: 100px;
                font-size: 16px;
                line-height: 25px;
                color: #333;
                opacity: 0.8;
                li {
                    width: 100%;
                    height: 50px;
                    margin-top: 20px;
                    position: relative;
                    .cth1 {
                        width: 100%;
                        height: 100%;
                        line-height: 25px;
                        font-size: 15px;
                        .chImg {
                            position: absolute;
                            top: 10%;
                            left: -3%;
                        }
                        span {
                            font-weight: 900;
                            color: black;
                        }
                    }
                }
            }
        }
    }
}
</style>
