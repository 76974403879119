<template>
  <!-- 服务保障 -->
  <div class="theService">
    <!-- 搬迁 -->
    <TsMore></TsMore>
    <!-- 安全 -->
    <TsSecurity></TsSecurity>
  </div>
</template>

<script>
import TsMore from "../components/content/TheService/TsMore/TsMore.vue";
import TsSecurity from "../components/content/TheService/TsSecurity/TsSecurity.vue";

export default {
  name: "TheService",
  components: {
    TsMore,
    TsSecurity,
  },
};
</script>

<style lang="less" scoped>
.theService {
  margin: 0 auto;
}
</style>
