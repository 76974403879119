<template>
    <div class="contentFour">
        <div class="ctfBox">
            <!-- 左边文字 -->
            <div class="ctfLeftText wow animate__animated animate__slideInLeft">
                <!-- 顶部图片 -->
                <div class="ctfTopImg">
                    <img class="ctfImg" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="ctfBig">威有数据迁移服务</div>
                <div class="ctfSmall">
                    互联网收录量短期保护、数据库文件备份及迁移、图片文字资料的转移(版面适应性、编辑器适应性、响应式适配)、数据迁移接口开发。
                </div>
            </div>
            <!-- 右边图片 -->
            <div class="ctfRightImg">
                <img
                    class="ctfImg wow animate__animated animate__slideInRight"
                    src="https://iv.okvu.cn/vugw/img/img4.png"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentFour",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.contentFour {
    width: 100%;
    height: 500px;
    background-color: white;
    .ctfBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        // 左边文字
        .ctfLeftText {
            width: 50%;
            height: 60%;
            position: absolute;
            bottom: 20%;
            float: left;
            .ctfTopImg {
                width: 68px;
                height: 70px;
                position: relative;
                overflow: hidden;
                .ctfImg {
                    position: absolute;
                    top: -145px;
                    left: -244px;
                }
            }
            .ctfBig {
                width: 100%;
                height: 70px;
                line-height: 70px;
                font-size: 30px;
                font-weight: 900;
            }
            .ctfSmall {
                width: 100%;
                height: 100px;
                font-size: 16px;
                line-height: 25px;
                margin-top: 20px;
                color: #333;
                opacity: 0.8;
            }
        }
        // 右边图片
        .ctfRightImg {
            width: 45%;
            height: 80%;
            float: right;
            margin-top: 5%;
            .ctfImg {
                margin: 0 auto;
                display: block;
                margin-top: 30px;
            }
        }
    }
}
</style>
