<template>
    <div class="contentTwo">
        <div class="cttBox">
            <!-- 左边文字 -->
            <div class="ctLeftText wow animate__animated animate__slideInLeft">
                <!-- 顶部图片 -->
                <div class="ctTopImg">
                    <img class="cttImg" src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <div class="cttBig">
                    威有网站改版，全面改善访客体验，<br />
                    兼顾旧访客浏览习惯
                </div>
                <div class="cttSmall">
                    遵守网页 UI
                    的科学要求同时，对原网站的各种内容、应用和功能做全面考虑，统筹规划，不因改版让原访客感到无所适从而产生抵触心理，又以科学合理的新体验赢得新访客。
                </div>
            </div>
            <!-- 右边图片 -->
            <div class="ctRightImg">
                <img
                    class="ctrImg wow animate__animated animate__slideInRight"
                    src="https://iv.okvu.cn/vugw/img/img2.png"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentTwo",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less" scoped>
.contentTwo {
    width: 100%;
    height: 600px;
    background-color: white;
    .cttBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        // 左边文字
        .ctLeftText {
            width: 50%;
            height: 60%;
            position: absolute;
            bottom: 50px;
            float: left;
            // 顶部图片
            .ctTopImg {
                width: 90px;
                height: 70px;
                position: relative;
                overflow: hidden;
                .cttImg {
                    position: absolute;
                    top: 0px;
                    left: -1208px;
                }
            }
            .cttBig {
                width: 100%;
                height: 100px;
                line-height: 50px;
                font-size: 36px;
                font-weight: 900;
                margin-top: 20px;
            }
            .cttSmall {
                width: 100%;
                height: 100px;
                font-size: 16px;
                line-height: 25px;
                margin-top: 20px;
                color: #333;
                opacity: 0.8;
            }
        }
        // 右边图片
        .ctRightImg {
            width: 50%;
            height: 80%;
            float: right;
            margin-top: 5%;
            .ctrImg {
                margin: 0 auto;
                display: block;
                margin-top: 100px;
            }
        }
    }
}
</style>
