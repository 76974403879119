<template>
    <div class="contentOne" v-if="getAdvData(44).children">
        <img :src="getAdvData(44).children[0].image" alt="" class="coBg" />
        <div class="ctoBox">
            <!-- 左边文字 -->
            <div class="coLeftText wow animate__animated animate__slideInDown">
                <div class="cltBig">网站改版与内容迁移</div>
                <div class="cltSmall">
                    用户第一，网站改版首要考虑的即是网站用户的访问体验，网站没有了用户那么改版就没有意义了，我们既要考虑新访客，又要顾及旧访客。
                </div>
            </div>
            <!-- 右边图片 -->
            <div class="coRightImg">
                <img
                    class="corImg wow animate__animated animate__slideInUp"
                    :src="getAdvData(44).children[0].children[0].image"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContentOne",
    inject: ["getAdvData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: false,
        });
    },
};
</script>

<style lang="less" scoped>
.contentOne {
    width: 100%;
    height: 600px;
    position: relative;
    .coBg {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }
    .ctoBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        position: relative;
        // 左边文字
        .coLeftText {
            width: 30%;
            height: 50%;
            position: absolute;
            bottom: 50px;
            float: left;
            .cltBig {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 36px;
                font-weight: 900;
                color: white;
            }
            .cltSmall {
                width: 100%;
                height: 100px;
                font-size: 16px;
                line-height: 25px;
                margin-top: 50px;
                color: #fff;
                opacity: 0.8;
            }
        }
        // 右边图片
        .coRightImg {
            width: 60%;
            height: 100%;
            float: right;
            margin-right: 80px;
            .corImg {
                // width: 100%;
                // height: 100%;
                margin-top: 30px;
            }
        }
    }
}
</style>
