<template>
    <!-- 服务保障-搬迁 -->
    <div class="tsMore">
        <!-- 内容一 -->
        <ContentOne></ContentOne>
        <!-- 内容二 -->
        <ContentTwo></ContentTwo>
        <!-- 内容三 -->
        <ContentThree></ContentThree>
        <!-- 内容四 -->
        <ContentFour></ContentFour>
    </div>
</template>

<script>
import ContentOne from "./ContentOne.vue";
import ContentTwo from "./ContentTwo.vue";
import ContentThree from "./ContentThree.vue";
import ContentFour from "./ContentFour.vue";

export default {
    name: "TsMore",
    components: {
        ContentOne,
        ContentTwo,
        ContentThree,
        ContentFour,
    },
};
</script>

<style lang="less" scoped>
.tsMore {
    min-width: 1423px;
    margin: 0 auto;
    text-align: left;
    overflow-x: hidden;
}
</style>
